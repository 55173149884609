import React from "react"
import Entertainment from "../components/Entertainment"
import SEO from "../components/SEO"
import Layout from "../components/Layout"

const EntertainmentPage = () => {
  return (
    <Layout>
      <SEO title="Entertainment" />
      <Entertainment />
    </Layout>
  )
}

export default EntertainmentPage
