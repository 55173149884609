const ENTERTAINMENT_DATA = [
  {
    img: "entertainment/EntertainmentImages/1.jpg",
  },
  {
    img: "entertainment/EntertainmentImages/2.jpg",
  },
  {
    img: "entertainment/EntertainmentImages/3.jpg",
  },
  {
    img: "entertainment/EntertainmentImages/4.jpg",
  },
  {
    img: "entertainment/EntertainmentImages/5.jpg",
  },
  {
    img: "entertainment/EntertainmentImages/6.jpg",
  },
  {
    img: "entertainment/EntertainmentImages/7.jpg",
  },
  {
    img: "entertainment/EntertainmentImages/7b.jpg",
  },
  {
    img: "entertainment/EntertainmentImages/8.jpg",
  },
  {
    img: "entertainment/EntertainmentImages/9.jpg",
  },
  {
    img: "entertainment/EntertainmentImages/10.jpg",
  },
  {
    img: "entertainment/EntertainmentImages/12.jpg",
  },
  {
    img: "entertainment/EntertainmentImages/13.jpg",
  },
  {
    img: "entertainment/EntertainmentImages/13b.jpg",
  },
  {
    img: "entertainment/EntertainmentImages/14.jpg",
  },
  {
    img: "entertainment/EntertainmentImages/14b.jpg",
  },
  {
    img: "entertainment/EntertainmentImages/15.jpg",
  },
  {
    img: "entertainment/EntertainmentImages/16.jpg",
  },
  {
    img: "entertainment/EntertainmentImages/17.jpg",
  },
  {
    img: "entertainment/EntertainmentImages/18.jpg",
  },
]

export default ENTERTAINMENT_DATA
