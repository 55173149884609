import React, { useState, useRef, useEffect } from "react"
import styled from "styled-components"
import media from "../../styles/media"
import ENTERTAINMENT_DATA from "./data"
import { Swiper } from "swiper/dist/js/swiper.esm"
import ImageWrapper from "../../components/elements/imageWrapper"
import { FormContainer } from "../Apply"
import { Formik } from "formik"
import HubspotForm from "../HubspotForm"

const Container = styled.div`
  .swiper-container {
    height: 275px;
    .swiper-wrapper {
      .swiper-slide {
        .gatsby-image-wrapper {
          height: 275px;
        }
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }
  ${media.tablet`
    padding: 0 0 var(--gutter-l) var(--gutter-l);
    .swiper-container {
      .swiper-wrapper {
        .swiper-slide {
          .gatsby-image-wrapper {
            width: 100%
            height: 100%
          }
          width: 56vw;
          &:last-child {
            margin-right: 45vw;
          }
        }
      }
    }
  `}
  ${media.laptop`
      .swiper-container {
        height: 396px;
      }
  `}
`
const Heading = styled.h1`
  font-weight: 600;
  text-align: center;
  font-size: 3rem;
  ${media.tablet`
    position: relative;
    text-align: left;
    transform: translateY(35%);
    font-size: 5.5rem;
    z-index: 2;
  `}
`

const SwiperNav = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 3rem;
  span {
    margin: 5px 2px;
    font-size: 0.9em;
  }
  .arrows {
    span {
      padding: 3rem 0;
      &.disabled {
        pointer-events: none;
        opacity: 0.25;
      }
      &#left-arrow {
        padding-right: 2.5rem;
      }
    }
  }
  ${media.tablet`
    padding: 0;
    padding-top: 0.5rem;
    width: 56vw;
  `}
`

const Content = styled.div`
  padding-top: var(--gutter-s);
  .description {
    p {
      padding: var(--gutter-s);
    }
  }
  ${media.tablet`
    padding-top: var(--gutter-l);
    display: grid;
    grid-template-columns: 1fr 1fr;
    p {
      font-size: 1.2rem;
    }
  `}
`

const Times = styled.div`
  padding: var(--gutter-s);
  text-align: center;
  display: none;
  div {
    background: var(--concrete);
    padding: 25px;
  }
  h1 {
    text-align: center;
    font-size: 2.5rem;
    font-weight: 600;
    padding-bottom: 1rem;
  }
  p {
    padding-bottom: 10px;
  }
  .day {
    font-weight: 600;
  }
  ${media.tablet`
    padding: 0;
    text-align: left;
    display: flex;
    justify-content: flex-end;
  display: none;
    div {
      padding: 50px;
    }
  `}
`

const Slide = styled.div`
  position: relative;
  .img-container {
    width: auto;
    height: 100%;
    overflow: hidden;
    img {
      width: auto;
      height: 100%;
      object-fit: cover;
      object-position: center center;
      transition: transform 2500ms ease-out !important;
      transform: ${props => (props.zoom ? `scale(1.1)` : `scale(1)`)};
    }
    &:hover {
      img {
        transition: transform 2500ms ease-out !important;
        transform: scale(1.1);
      }
    }
  }
`

const Entertainment = () => {
  const [zoom, setZoom] = useState(false)

  const renderEntertainmentData = () => {
    return ENTERTAINMENT_DATA.map((entertainment, index) => {
      return (
        <Slide key={index} className="swiper-slide" zoom={zoom}>
          <div className="img-container">
            <ImageWrapper src={entertainment.img} />
          </div>
        </Slide>
      )
    })
  }

  const swiperElement = useRef(null)
  const swiper = useRef(null)

  const [activeSlide, setActiveSlide] = useState(0)
  const [showFormSuccess, setFormSuccess] = useState(false)

  useEffect(() => {
    swiper.current = new Swiper(swiperElement.current, {
      speed: 1000,
      freeMode: true,
      freeModeSticky: true,
      breakpointsInverse: true,
      touchStartPreventDefault: false,
      slidesPerView: 1,
      spaceBetween: 30,
      centeredSlides: true,
      breakpoints: {
        768: {
          slidesPerView: "auto",
          centeredSlides: false,
        },
      },
      on: {
        transitionEnd: () => {
          if (swiper.current) {
            setActiveSlide(swiper.current.activeIndex)
          }
        },
        touchStart: () => {
          setZoom(true)
        },
        touchEnd: () => {
          setZoom(false)
        },
      },
    })
  }, [])

  const slideNext = () => {
    if (swiper.current) {
      swiper.current.slideNext()
    }
  }

  const slidePrev = () => {
    if (swiper.current) {
      swiper.current.slidePrev()
    }
  }
  return (
    <>
      <Container>
        <Heading>entertainment</Heading>
        <div ref={swiperElement} className="swiper-container slider-swipe">
          <div className="swiper-wrapper">{renderEntertainmentData()}</div>
        </div>

        <SwiperNav>
          <div>
            <span>
              {activeSlide < 9 ? "0" : null}
              {activeSlide + 1}
            </span>
            <span className="divider">&#8213;</span>
            <span>{ENTERTAINMENT_DATA.length}</span>
          </div>
          <div className="arrows">
            <span
              onClick={slidePrev}
              id="left-arrow"
              className={activeSlide == 0 ? "disabled" : "mouse-link"}
            >
              &#8592;
            </span>
            <span
              onClick={slideNext}
              className={
                activeSlide + 1 == ENTERTAINMENT_DATA.length
                  ? "disabled"
                  : "mouse-link"
              }
            >
              &#8594;
            </span>
          </div>
        </SwiperNav>

        <Content>
          <div className="description">
            <p>
              Music is a integral part of our daily lives, especially as
              tattooers, music is always playing in the shop. It&apos;s part of
              the experience. That&apos;s exactly why the only &apos;side
              shows&apos; you&apos;ll see at this convention is live bands. The
              convention hopes to add to your experience of getting tattooed by
              enjoying a selection of bands we think you will love.
            </p>
            <p>
              Come grab a beer and some good food from our favourite food trucks
              and let&apos;s have some good times!
            </p>
          </div>
          <Times>
            <div>
              <h1>2020 bands</h1>
              <p className="day">Friday</p>
              <p>
                20:00<span>.................................</span>TBC
              </p>
              <p>
                21:00<span>.................................</span>TBC
              </p>
              <p className="day">Saturday</p>
              {/* <p>
                12:00<span>.................................</span>TBC
              </p>
              <p>
                14:00<span>.................................</span>TBC
              </p>
              <p>
                16:00<span>.................................</span>TBC
              </p>
              <p>
                18:00<span>.................................</span>TBC
              </p> */}
              <p>
                20:00<span>.................................</span>TBC
              </p>
              <p>
                21:00<span>.................................</span>TBC
              </p>
              <p>
                22:00<span>.................................</span>TBC
              </p>
              <p className="day">Sunday</p>
              {/* <p>
                12:00<span>.................................</span>TBC
              </p> */}
              <p>
                14:00<span>.................................</span>TBC
              </p>
              {/* <p>
                16:00<span>.................................</span>TBC
              </p> */}
            </div>
          </Times>
        </Content>
      </Container>
      <FormContainer>
        <p className="form-text">
          If you&apos;d like to perform at the event <br /> complete the form
          below and we&apos;ll get back to you asap.
        </p>
        <HubspotForm formScriptID="4be472c6-cb22-46ad-8d1c-81688182df19" />
      </FormContainer>
    </>
  )
}

export default Entertainment
